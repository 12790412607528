// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\alielias\\Downloads\\coding\\Teampics\\LANDING_Teampics\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\alielias\\Downloads\\coding\\Teampics\\LANDING_Teampics\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\alielias\\Downloads\\coding\\Teampics\\LANDING_Teampics\\.cache\\data.json")

